<template>
  <div>
    <div>
      <div class="w3-col w3-left-align">
        <content-header v-bind:title="$t('reports')"></content-header>
      </div>
    </div>

    <div class="content-sidebars sidebars-60-40">
      <div class="report-title-left-align" style="height: 70px">
        <h5 v-on:click="seenVisitorList = !seenVisitorList" class="flow-blue-color cursor-pointer" style="width: 15%">
          {{ $t('visitor-report') }}
          <i v-if="seenVisitorList" class="far fa-caret-square-up"></i>
          <i v-if="!seenVisitorList" class="far fa-caret-square-down"></i>
        </h5>
      </div>
      <div v-if="seenVisitorList"
           class="spoiler-open-card w3-padding">
        <div>
          <label class="w3-left-align flow-blue-color flow-flex-row">{{ $t('pick-a-day') }}</label>
          <p class="flow-padding-bottom zero-top w-25pc flow-flex-row">
            <input type="date" v-model="selectedDay" @change="getAllVisitorsOfDay" max="9999-12-31"
                   pattern="\d{4}-\d{2}-\d{2}"
                   class="w3-input w3-border w3-round-medium"/>
            <button class="flow-btn w3-btn w3-round w3-border w3-ripple w3-round-medium" style="margin-left: 20px"
                    @click="exportPDF" title="PDF">
              <i class="fa fa-file-pdf w3-text-red"></i>
            </button>
          </p>
        </div>
        <div class="w3-container flow-card blue-top w3-animate-opacity w3-padding">
          <table id="visitor-table" class="w3-table w3-bordered flow-table">
            <thead class="flow-blue-color">
            <tr>
              <th>{{ "id" }} <i @click="sortList('id')" class="fas fa-sort cursor-pointer"></i></th>
              <th>{{ $t("name") }} <i @click="sortList('lastName', 'firstName')" class="fas fa-sort cursor-pointer"></i>
              </th>
              <th>{{ $t("email") }} <i @click="sortList('email')" class="fas fa-sort cursor-pointer"></i></th>
              <th>{{ $t("phone") }} <i @click="sortList('phone')" class="fas fa-sort cursor-pointer"></i></th>
              <th>{{ $t("company") }} <i @click="sortList('company')" class="fas fa-sort cursor-pointer"></i></th>
              <th>{{ $t("contact") }} <i @click="sortList('contactLastName', 'contactFirstName')"
                                         class="fas fa-sort cursor-pointer"></i></th>
              <th>{{ $t("appointmentTime") }} <i @click="sortList('appointmentTime')"
                                                 class="fas fa-sort cursor-pointer"></i></th>
              <th>{{ $t("arrivalTime") }} <i @click="sortList('arrivalTime')" class="fas fa-sort cursor-pointer"></i>
              </th>
            </tr>
            </thead>
            <tbody v-show="officeVisitors.length === 0">
            <tr>
              <td class="w3-center flow-blue-color" colspan="8">
                <b class="font-size-1pt25em">{{ $t("noVisitors") }}</b>
                {{ officeVisitors }}
              </td>
            </tr>
            </tbody>

            <tbody v-show="officeVisitors.length !== 0">
            <tr v-for="visitor in officeVisitors"
                v-bind:id="visitor.id" :key="visitor.id">
              <td>
                <span> {{ visitor.id }} </span>
              </td>
              <td>
                <span>  {{ visitor.lastName }} {{ visitor.firstName }}</span>
              </td>
              <td>
                <span> {{ visitor.email }} </span>
              </td>
              <td>
                <span> {{ visitor.phoneNumber }} </span>
              </td>
              <td>
                <span> {{ visitor.company }} </span>
              </td>
              <td>
                <span>  {{ visitor.contactLastName }} {{ visitor.contactFirstName }}</span>
              </td>
              <td>
                <span>  {{ visitor.appointmentDate }} {{ visitor.appointmentTime }}</span>
              </td>
              <td>
                <span>  {{ visitor.arrivalDate }} {{ visitor.arrivalTime }}</span>
              </td>

            </tr>
            </tbody>
          </table>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import jsPDF from 'jspdf';
import 'jspdf-autotable'

export default {
  name: "VisitorReport",
  components: {ContentHeader},

  data() {
    return {
      contentLoaded: false,
      officeVisitors: [],
      selectedDay: new Date().toISOString().slice(0, 10),
      sortedByASC: true,
      exportVisitors: [],
      seenVisitorList: true
    }
  },
  mounted() {
    this.getAllVisitorsOfDay();
  },
  methods: {
    getAllVisitorsOfDay() {
      const app = this;
      app.officeVisitors = [];
      app.$restClient.visitors.getVisitors(this.selectedDay).then(response => {
        app.contentLoaded = true;
        let data = response.data;
        if (data.length > 0) {
          let officeVisitorList = JSON.parse(JSON.stringify(data));
          officeVisitorList.forEach((visitor, index) => { visitor.id = ++index });
          app.officeVisitors = officeVisitorList;
          this.sortList('lastName', 'firstName');
        }
      }).catch(err => {
        console.log(err);
        app.$notify.notifyError({title: this.$t("failed")});
      })
    },
    sortList(sortBy1, sortBy2) {
      if (this.sortedbyASC) {
        this.officeVisitors.sort((x, y) => {
          if (x[sortBy1] > y[sortBy1]) return -1;
          if (x[sortBy1] < y[sortBy1]) return 1;
          if (sortBy2 !== undefined && x[sortBy2] > y[sortBy2]) return -1;
          else return 1;
        });
        this.sortedbyASC = false;
      } else {
        this.officeVisitors.sort((x, y) => {
          if (x[sortBy1] < y[sortBy1]) return -1;
          if (x[sortBy1] > y[sortBy1]) return 1;
          if (sortBy2 !== undefined && x[sortBy2] < y[sortBy2]) return -1;
          else return 1;
        });
        this.sortedbyASC = true;
      }
    },
    exportPDF() {
      this.fillExportVisitors(this.officeVisitors);
      const app = this;
      const columns = [
        {title: 'id', dataKey: "id"},
        {title: app.$t('name'), dataKey: "name"},
        {title: app.$t('email'), dataKey: "email"},
        {title: app.$t('phone'), dataKey: "phone"},
        {title: app.$t('company'), dataKey: "company"},
        {title: app.$t('contact'), dataKey: "contact"},
        {title: app.$t('appointmentTime'), dataKey: "appointmentTime"},
        {title: app.$t('arrivalTime'), dataKey: "arrivalTime"}
      ];
      const doc = new jsPDF('landscape');
      doc.autoTable(columns, app.exportVisitors, {
        theme: 'striped',
        headerStyles: {fillColor: [9, 78, 119]},
        styles: {
          overflow: 'linebreak'
        }
      });
      doc.save(app.$t('visitor-report') + ".pdf");
    },
    fillExportVisitors(visitors) {
      this.exportVisitors = [];
      for (let i = 0; i < visitors.length; i++) {
        this.exportVisitors.push({
          id: visitors[i].id,
          name: visitors[i].lastName + ' ' + visitors[i].firstName,
          email: visitors[i].email,
          phone: visitors[i].phoneNumber,
          company: visitors[i].company,
          contact: visitors[i].contactLastName + ' ' + visitors[i].contactFirstName,
          appointmentTime: visitors[i].appointmentDate + ' ' + visitors[i].appointmentTime,
          arrivalTime: visitors[i].arrivalDate + ' ' + visitors[i].arrivalTime
        })
      }
    },
  },
}

</script>