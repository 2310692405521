<template>
  <div class="w3-left-align">
    <i class="fa fa-inbox flow-blue-color w3-xlarge w3-margin-right"></i>
    <!-- domain icon -->
    <ContentHeader v-bind:title="$t('editDomain')" />
    <div class="w3-margin-top w3-margin-bottom w3-left-align">
      <Column>
        <Row>
          <div class="grid-component">
            <StandardTextBox
              :np-name-error="!domain.name ? $t('INVALID_NAME') : ''"
              :text="domain.name"
              :label-name="$t('name')"
              @update-text="args => (domain.name = args)"
            />
          </div>
          <div class="grid-component">
            <StandardTextBox
              :text="organization"
              is-disabled="is-disabled"
              :label-name="$t('organization')"
              :placeholder="$t('organization')"
            />
          </div>
        </Row>
        <Row>
          <div class="grid-component">
            <label class="w3-left flow-blue-color flow-padding-bottom">{{
                $t("domainType")
              }}</label>
            <div class="w3-col w-100pc flow-margin-right sort-select">
              <StandardSelect
                  :callback="setSelectedType"
                  :items="this.getDomainTypes"
                  :preSelectedItem="domain.type"
                  :valueKey="'name'"
                  :valueKeyIndex="'id'"
                  class="w3-border w3-round-medium"
                  type="text"
              />
            </div>
          </div>
          <div class="grid-component">
            <StandardTextBox
              :text="domain.prefix"
              is-disabled="is-disabled"
              :label-name="$t('prefix')"
              :placeholder="$t('prefix')"
            />
          </div>
        </Row>
        <Row>
          <div class="grid-component">
            <label class="w3-left flow-blue-color flow-padding-bottom">{{
                $t("domain-message")
              }}</label>
            <div class="w3-col w-100pc flow-margin-right sort-select">
            <StandardSelect
                :callback="setSelectedNotificationText"
                :items="this.getStandardTexts"
                :preSelectedItem="domain.notificationText"
                :valueKey="'value'"
                :valueKeyIndex="'key'"
                class="w3-border w3-round-medium"
                type="text"
            />
            </div>
          </div>
          <div class="grid-component">
            <StandardTextBox
              :np-name-error="showMinutesError ? minutesError : ''"
              :text="domain.closingTimeMinutes"
              :label-name="$t('closing-message-threshold')"
              :placeholder="$t('minutes')"
              :is-number="true"
              @update-text="args => (domain.closingTimeMinutes = args)"
            />
          </div>
        </Row>
        <Row>
          <div class="grid-component">
            <StandardTextBox
              :text="domain.createdAt"
              is-disabled="is-disabled"
              :label-name="$t('created-at')"
              :placeholder="$t('created-at')"
            />
          </div>

          <div class="grid-component">
            <StandardTextBox
              :text="domain.updatedAt"
              is-disabled="is-disabled"
              :label-name="$t('updated-at-and-by')"
              :placeholder="$t('updated-at')"
            />
          </div>

          <div class="grid-component">
            <StandardTextBox
              :text="domain.updatedBy"
              is-disabled="is-disabled"
              :label-name="$t('updated-by')"
              :placeholder="$t('updated-by')"
            />
          </div>
        </Row>
      </Column>

      <div class="w3-container zero-bottom zero-top">
        <SaveButton
          :function-executed="updateExecuted"
          :on-add="
            () => {
              updateDomain();
            }
          "
        />

        <BackButton :on-clicked="() => sideFormStatusEvent('close')" />
      </div>
    </div>
  </div>
</template>

<script>
import ContentHeader from "../../common/ContentHeader";
import BackButton from "@/components/common/buttons/BackButton.vue";
import { mapActions, mapGetters } from "vuex";
import SaveButton from "@/components/common/buttons/SaveButton.vue";
import StandardTextBox from "@/components/common/StandardTextBox.vue";
import Column from "@/components/common/grid/Column.vue";
import Row from "@/components/common/grid/Row.vue";
import StandardSelect from "@/components/views/opening-hours/StandardSelect.vue";

export default {
  name: "EditDomainForm",
  components: {
    StandardSelect,
    Row,
    Column,
    StandardTextBox,
    SaveButton,
    BackButton,
    ContentHeader,
  },

  data() {
    return {
      domain: {},
      updateExecuted: false,
      showMinutesError: false,
      minutesError: ""
    };
  },
  mounted() {

  },
  created() {
    this.domain = JSON.parse(JSON.stringify(this.onSelectedDomain));
    this.setDomain();
  },
  watch: {
    onSelectedDomain() {
      this.domain = JSON.parse(JSON.stringify(this.onSelectedDomain));
      if (this.domain && this.domain.notificationTextKey) {
        this.domain.notificationText = this.getStandardTexts.find(
          item => item.key === this.domain.notificationTextKey
        );
      }
    }
  },
  methods: {
    ...mapActions({
      sideFormStatusEvent: "eventHandlers/sideFormStatusEvent",
      updatedDomainEvent: "eventHandlers/updatedDomainEvent"
    }),
    checkClosingTimeMessageAndMinutes() {
      this.showMinutesError = false;
      this.minutesError = "";
      let minutes = this.domain.closingTimeMinutes;
      let textKey = this.domain.notificationTextKey;

      if (minutes && (minutes > 180 || minutes < 0)) {
        this.showMinutesError = true;
        this.minutesError = this.$t("INVALID_MINUTES");
      } else if (minutes && minutes > 0 && !textKey) {
        this.showMinutesError = true;
        this.minutesError = this.$t("INVALID_NOTIFICATION_TEXT");
      } else if (textKey && (!minutes || minutes === 0 || minutes === "0")) {
        this.showMinutesError = true;
        this.minutesError = this.$t("INVALID_CLOSING_TIME_MINUTES");
      }
    },
    setSelectedNotificationText(item) {
      if (item) {
        this.domain.notificationText = item;
        this.domain.notificationTextKey = item.key;
      }
    },
    setDomain() {
      this.domain.notificationText = this.getStandardTexts.find(
        item => item.key === this.domain.notificationTextKey
      );
    },

    updateDomain() {
      if (!this.domain.name || !this.domain.name.trim()) {
        return;
      }

      this.checkClosingTimeMessageAndMinutes();
      if (this.showMinutesError) {
        return;
      }

      let userId = this.$store.getters["users/getCurrentUser"].id;

      this.updateExecuted = true;

      this.$restClient.domains
        .update(this.domain, userId)
        .then(response => {
          // eslint-disable-next-line no-unused-vars
          console.log(response);
          this.updatedDomainEvent(this.domain);
          this.$notify.notifySuccess({
            title: this.$t("success"),
            message: this.$t("modified-success")
          });
          this.sideFormStatusEvent("close");
        })
        .catch(err => {
          this.updateExecuted = false;
          if (err && err.response.status === 400)
            this.$notify.notifyError({
              title: this.$t("failed"),
              message: this.$t("domain-name-unique")
            });
          console.log(err);
          this.$notify.notifyError({ title: this.$t("failed") });
        });
    },

    setSelectedType(option) {
      this.domain.type = option;
    }
  },
  computed: {
    ...mapGetters({
      onSelectedDomain: "eventHandlers/onSelectedDomain",
      getDomainTypes: "eventHandlers/getDomainTypes",
      getStandardTexts: "eventHandlers/getStandardTexts"
    }),
    organization: {
      get() {
        if (this.domain && this.domain.type) {
          return this.domain.type.organization.name;
        } else {
          return "";
        }
      },
      // eslint-disable-next-line no-unused-vars
      set(val) {
        // no need to set organization
      }
    }
  }
};
</script>
