import {createRouter, createWebHistory} from 'vue-router';
import Layout from './components/common/Layout.vue';
// import Welcome from "./components/Welcome.vue";
import AccountSetup from "./components/views/AccountSetup.vue";
import Desks from "@/components/views/desks/Desks";
import Domains from "@/components/views/domains/Domains";
import OpeningHours from "@/components/views/opening-hours/OpeningHours";
import Languages from "@/components/views/languages/Languages";
import WaitingRooms from "@/components/views/waiting-rooms/WaitingRooms";
import WalkingInstructions from "@/components/views/walking-instructions/WalkingInstructions";
import StandardTexts from "@/components/views/standard-texts/StandardTexts";
import ListEntry from "@/components/views/aanmelding/ConnectDesk";
import VisitList from "@/components/views/aanmelding/VisitList";
import Logout from "@/components/Logout";
import PathNotFound from "@/components/PathNotFound";
import AccountDetails from "./components/views/user/AccountDetails";
import WaitingRoomScreen from "@/components/views/WaitingRoomScreen";
import Message from "@/components/views/message-board/Messages.vue";
import Dashboard from "@/components/views/Dashboard.vue";
import VisitorReport from "@/components/views/visitor-report/VisitorReport.vue";

// import Dashboard from './components/views/Dashboard.vue';
//import i18n from "./lang/lang";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        {
            path: "/layout", component: Layout, children: [
                {path: "/", alias: ["/welcome","/dashboard"], component: Dashboard, meta: {NORMAL: true, ADMIN: true}},
                {path: "/sites/:siteId/accountDetails", name:"accountDetails", component: AccountDetails,meta:{NORMAL: true, ADMIN: true}},
                {path: "/sites/:siteId/dashboard", name: "dashboard", component: Dashboard, meta: {NORMAL: true, ADMIN: true}},
                {path: "/sites/:siteId/home", name: "home", component: ListEntry, meta: {NORMAL: true, ADMIN: true}},
                {
                    path: "/sites/:siteId/deskDetails",
                    name: "deskDetails",
                    component: ListEntry,
                    meta: {NORMAL: true, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/desks/:deskId/users/:userId",
                    name: "visit",
                    component: VisitList,
                    meta: {NORMAL: true, ADMIN: true}
                },
                {path: "/sites/:siteId/sites", name: 'sites', component: ListEntry, meta: {NORMAL: true, ADMIN: true}},
                {
                    path: "/sites/:siteId/domains",
                    name: 'domains',
                    component: Domains,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/opening_hours",
                    name: 'opening_hours',
                    component: OpeningHours,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {path: "/sites/:siteId/desks", name: 'desks', component: Desks, meta: {NORMAL: false, ADMIN: true}},
                {
                    path: "/sites/:siteId/languages",
                    name: 'languages',
                    component: Languages,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/standard_texts",
                    name: 'standard_texts',
                    component: StandardTexts,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/message_board",
                    name: 'message_board',
                    component: Message,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/visitor_report",
                    name: 'visitor_report',
                    component: VisitorReport,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/languages",
                    name: 'languages',
                    component: Languages,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/waiting_rooms",
                    name: 'waiting_rooms',
                    component: WaitingRooms,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/instructions",
                    name: 'instructions',
                    component: WalkingInstructions,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/account_setup",
                    name: 'account_setup',
                    component: AccountSetup,
                    meta: {NORMAL: false, ADMIN: true}
                },
                {
                    path: "/sites/:siteId/logout",
                    name: "logout",
                    component: Logout,
                    meta: {NORMAL: true, ADMIN: true}
                }
            ]
        },

        {
            path: '/:pathMatch(.*)*',
            component: PathNotFound,
            query: {reason: 'other'},
            meta: {NORMAL: true, ADMIN: true}
        },
        {
            path: '/screen/:screenId',
            name: "waitingRoomScreen",
            component: WaitingRoomScreen,
            meta: {NORMAL: true, ADMIN: true}
        },
        {
            path: '/problem',
            component: PathNotFound,
            meta: {NORMAL: true, ADMIN: true}
        }
    ],
});
export default router;
